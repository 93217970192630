<template>
  <ag-card-container
    title="En cours"
    fluid
    no-content-padding
  >
    <template #left>
      <v-btn
        :disabled="!inProgressPart"
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
      <v-menu
        v-model="settingsMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="accent"
            fab
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>settings</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="computedCategoryMode"
                  inset
                  hide-details
                />
              </v-list-item-action>
              <v-list-item-title>Afficher par catégories d'opération</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="workloadMode"
                  inset
                  hide-details
                />
              </v-list-item-action>
              <v-list-item-title>Afficher l'encours en valeurs temporelle</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>

    <v-tabs
      v-model="tab"
      background-color="primary"
      color="primary"
      :slider-size="5"
      slider-color="accent"
      grow
    >
      <v-tab
        v-for="type in tabList"
        :key="type.data"
        v-text="type.name"
      />
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="type in tabList"
        :key="type.data"
      >
        <v-row v-if="loadingData" justify="center" class="ma-2">
          <v-progress-circular
            style="text-center"
            class="my-8"
            :size="80"
            width="8"
            indeterminate
            color="primary"
          />
        </v-row>
        <in-progress-table
          v-else-if="inProgressPart"
          :value="inProgressPart.results[type.data]"
          :category-computed-value="inProgressPart.categoryComputedResults[type.data]"
          :workload-mode="workloadMode"
          :headers="inProgressPart.headers[type.data]"
          :computed-category-mode="computedCategoryMode"
          :first-header="type.firstHeader"
        />
      </v-tab-item>
    </v-tabs-items>
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import InProgressTable from '../components/InProgressTable'

export default {
  name: 'InProgressContainer',
  components: {
    AgCardContainer,
    InProgressTable,
  },
  data: () => ({
    tab: null,
    inProgressPart: null,
    loadingData: false,
    loadingExport: false,
    computedCategoryMode: false,
    workloadMode: false,
    settingsMenu: false,
  }),
  computed: {
    tabList () {
      return [
        { name: 'Collodion', data: 'Collodion', firstHeader: 'status' },
        { name: 'Bobine', data: 'Bobine', firstHeader: 'status' },
        { name: 'Faisceau', data: 'Faisceau', firstHeader: 'status' },
        { name: 'Petits modules', data: 'pModule', firstHeader: 'operation' },
        { name: 'Grands modules', data: 'gModule', firstHeader: 'operation' },
      ]
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.fetchData()
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData () {
      try {
        this.loadingData = true
        this.inProgressPart = await Repositories.part.getInProgress()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const dataArray = []
        // eslint-disable-next-line no-restricted-syntax
        for (const type of this.tabList) {
          const exportConfig = {
            columns: [
              { header: type.firstHeader, key: type.firstHeader },
            ],
            view: [{ state: 'frozen', xSplit: 1, ySplit: 1 }],
            sheetName: type.name,
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const header of this.inProgressPart.headers[type.data]) {
            if (this.workloadMode) {
              exportConfig.columns.push({ header, key: `${header}_workload` })
            }
            else {
              exportConfig.columns.push({ header, key: header })
            }
          }
          if (this.workloadMode) {
            exportConfig.columns.push({ header: 'Total', key: 'totalWorkload' })
          }
          else {
            exportConfig.columns.push({ header: 'Total', key: 'total' })
          }

          const data = this.computedCategoryMode
            ? this.inProgressPart.categoryComputedResults[type.data]
            : this.inProgressPart.results[type.data]
          dataArray.push({ dataToExport: data, config: exportConfig })
        }
        let fileName = 'En cours'
        if (this.computedCategoryMode) {
          fileName += '_Par catégories'
        }
        if (this.workloadMode) {
          fileName += '_En secondes'
        }
        await createExcelFile(dataArray, fileName)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>
<style scodep>
.v-menu__content {
  z-index: 9 !important;
}
</style>
